import React from 'react';

import Layout from 'src/components/layout';
import SEO from 'src/components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="text-center">
      <h2 className="text-2xl font-bold my-8 p-3">
        404 Error - Page not found
      </h2>
    </div>
  </Layout>
);

export default NotFoundPage;
